<template>
  <div class="">
    <el-dialog v-model="createSessionDialogVisible" :width="dialogWidth" title="Créer une nouvelle série" class="w-full" center
      :before-close="closeModal" destroy-on-close>

      <div class="flex flex-col items-center text-center text-sm px-3 gap-5">
        <div class="flex sm:flex-row flex-col justify-between items-start w-full sm:gap-0 gap-4">
          <div class="sm:w-1/2 w-full">
            <div class="mb-2">
              <p class="input-label text-left">Modules</p>
            </div>
            <div class="relative mb-2 form-input-wrapper">
              <svg width="20" height="20" fill="currentColor"
                class="">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" />
              </svg>
              <input v-model="searchm" placeholder="Recherche"
                class="form-input"
                type="text" aria-label="Filter projects" />
            </div>
            <el-scrollbar height="146px" class="w-full border rounded-md bg-white">
              <w-list v-model="selectionm" :items="filterModules" :multiple=false @item-click="loadCourses($event)"
                class="mt6 mr4 grow">
              </w-list>
            </el-scrollbar>
          </div>


          <div class="sm:w-1/2 w-full sm:ml-4 sm:mt-0">
            <div class="mb-2">
              <p class="input-label text-left">Cours</p>
            </div>
            <div class="relative mb-2 form-input-wrapper">
              <svg width="20" height="20" fill="currentColor">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" />
              </svg>
              <input v-model="searchc" placeholder="Recherche"
                class="form-input"
                type="text" aria-label="Filter projects" />
            </div>
            <el-scrollbar height="146px" class="w-full border rounded-md bg-white">
              <w-list v-model="selectionc" :items="filterCourses" color="deep-purple" :multiple=true
                @item-click="getCount" class="mt6 mr4 grow">
              </w-list>
            </el-scrollbar>
          </div>

        </div>


        <div class="w-full">
          <div class="mb-2">
            <p class="input-label text-left">Types de questions</p>
          </div>
          <el-select v-model="questiontype" @change="getCount" multiple placeholder="Types de questions" class="w-full">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="w-full flex flex-col justify-between">
          <div class="mb-2">
            <p class="input-label text-left">Année</p>
          </div>
          <div class="flex flex-row gap-4">
            <el-date-picker class="w-full" style="width: 100% !important;" v-model="interval_D" type="year"
              placeholder="Année debut" value-format="x" @change="getCount" />

            <el-date-picker class="w-full" style="width: 100% !important;" v-model="interval_F" type="year"
              placeholder="Année Fin" value-format="x" @change="getCount" />
          </div>

        </div>

        <div class="flex sm:flex-row flex-col justify-between items-start w-full sm:gap-0 gap-4">
          <div class="sm:w-1/2 sm:mr-2 w-full">
            <div class="mb-2">
              <p class="input-label text-left">Nom de la série</p>
            </div>
            <div class="relative mb-2 form-input-wrapper">
              <input v-model="namesession" placeholder="Nom de la série"
                class="form-input"
                type="text" />
            </div>
            <!--<el-input v-model="namesession" class="w-full" placeholder="Nom de la série" />-->
          </div>
          <div class="sm:w-1/2 sm:ml-2 w-full">
            <div class="mb-2">
              <p class="input-label text-left">Source</p>
            </div>
            <el-select v-model="source_id" @change="getCount" multiple placeholder="Source" class="w-full">
              <el-option v-for="item in sources" :key="item.id" :label="item.title" :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="w-full">
          <!--<div class="w-full">
            <div class="mb-2">
              <p class="font-semibold text-sm text-gray-800 text-left ml-2">Nombre de questions</p>
            </div>
            <el-input-number class="w-full" v-model="numberq" :min="0" :max="this.max"></el-input-number>
          </div>-->
          <div class="mb-2">
            <p class="input-label text-left">Nombre de questions</p>
          </div>
          <div class="input-number-wrapper">
            <div class="minus" @click="decrement()">
                <MinusIcon />
            </div>
            <div class="value">
              {{ numberq }}
            </div>
            <div class="plus" @click="increment()">
              <PlusIcon />
            </div>
          </div>
        </div>
      </div>


      <template #footer>
        <span class="dialog-footer flex">
          <button @click="closeModal" class="secondary-btn py-4 px-5 mx-1 font-bold w-full">
            Annuler
          </button>
          <button v-if="(numberq > 0 && selectionc.length > 0)" @click="createSession"
            class="primary-btn py-4 mx-1 font-bold w-full">
            Confirmer
          </button>
          <button v-else disabled @click="createSession" class="primary-btn py-4 px-4 mx-1 w-full">
            Confirmer
          </button>
        </span>
      </template>
    </el-dialog>
  </div>


  <!--<div class="sm:hidden block">
    <el-dialog v-model="createSessionDialogVisible" width="85%" title="Créer une nouvelle série" class=" w-full" center
      :before-close="closeModal" destroy-on-close>

      <div class=" flex flex-col items-center text-center text-sm px-3">

        <div class="flex sm:flex-row flex-col justify-between items-start w-full">
          <div class="sm:w-1/2 w-full sm:mr-4">
            <div class="mb-2">
              <p class="font-semibold text-sm text-gray-800 text-left ml-2">Modules</p>
            </div>
            <div class="relative mb-2">
              <svg width="20" height="20" fill="currentColor"
                class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" />
              </svg>
              <input v-model="searchm" placeholder="Recherche"
                class=" focus:ring-1 focus:ring-red-400 focus:outline-none w-full text-sm text-black placeholder-gray-500 border border-gray-300 rounded-md py-2 pl-10"
                type="text" aria-label="Filter projects" />
            </div>
            <el-scrollbar height="146px" class="w-full border rounded-md bg-white">
              <w-list v-model="selectionm" :items="filterModules" :multiple=false @item-click="loadCourses($event)"
                class="mt6 mr4 grow">
              </w-list>
            </el-scrollbar>
          </div>


          <div class="sm:w-1/2 w-full sm:ml-4 sm:mt-0 mt-5">
            <div class="mb-2">
              <p class="font-semibold text-sm text-gray-800 text-left ml-2">Cours</p>
            </div>
            <div class="relative mb-2">
              <svg width="20" height="20" fill="currentColor"
                class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" />
              </svg>
              <input v-model="searchc" placeholder="Recherche"
                class="focus:border-light-blue-500 focus:ring-1 focus:ring-red-400 focus:outline-none w-full text-sm text-black placeholder-gray-500 border border-gray-300 rounded-md py-2 pl-10"
                type="text" aria-label="Filter projects" />
            </div>
            <el-scrollbar height="146px" class="w-full border rounded-md bg-white">
              <w-list v-model="selectionc" :items="filterCourses" color="deep-purple" :multiple=true
                @item-click="getCount" class="mt6 mr4 grow">
              </w-list>
            </el-scrollbar>
          </div>

        </div>

        <div class="flex sm:flex-row flex-col justify-between items-start w-full mx-5 mt-5">
          <div class="sm:w-1/2 sm:mr-4 w-full">
            <div class="mb-2">
              <p class="font-semibold text-sm text-gray-800 text-left ml-2">Types de questions</p>
            </div>
            <el-select v-model="questiontype" @change="getCount" multiple placeholder="Types de questions"
              class="w-full">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="sm:w-1/2 mt-2 w-full">
            <div class="mb-2">
              <p class="font-semibold text-sm text-gray-800 text-left ml-2 ">Année</p>
            </div>
            <div class="flex flex-row justify-between">
              <el-date-picker class="w-auto mr-1" v-model="interval_D" type="year" placeholder="Année debut"
                value-format="x" @change="getCount" />

              <el-date-picker class="w-auto ml-1" v-model="interval_F" type="year" placeholder="Année Fin"
                value-format="x" @change="getCount" />
            </div>

          </div>
        </div>
        <div class="flex sm:flex-row flex-col justify-between items-start w-full m-5">
          <div class="sm:w-1/2 sm:mr-4 w-full">
            <div class="mb-2">
              <p class="font-semibold text-sm text-gray-800 text-left ml-2">Source</p>
            </div>
            <el-select v-model="source_id" @change="getCount" multiple placeholder="Source" class="w-full">
              <el-option v-for="item in sources" :key="item.id" :label="item.title" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="sm:w-1/2 sm:ml-4 w-full mt-2">
            <div class="mb-2">
              <p class="font-semibold text-sm text-gray-800 text-left ml-2">Nombre de questions</p>
            </div>
            <el-input-number class="w-full" v-model="numberq" :min="0" :max="this.max"></el-input-number>
          </div>
        </div>

        <div class="flex sm:flex-row flex-col justify-center items-start w-full">
          <div class="w-full">
            <div class="mb-2">
              <p class="font-semibold text-sm text-gray-800 text-left ml-2">Nom de la série</p>
            </div>
            <el-input v-model="namesession" class="w-full" placeholder="Nom de la série" />
          </div>
        </div>

      </div>


      <template #footer>
        <span class="dialog-footer">
          <button @click="closeModal"
            class="py-2 px-5 mx-1 text-gray-500 border font-normal text-sm bg-white rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">
            Annuler
          </button>
          <button v-if="(numberq > 0 && selectionc.length > 0)" @click="createSession"
            class="py-2 px-4 mx-1 text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">
            Confirmer
          </button>
          <button v-else disabled @click="createSession"
            class="py-2 px-4 mx-1 text-white font-normal text-sm bg-gray-400 cursor-default rounded-lg">
            Confirmer
          </button>
        </span>
      </template>
    </el-dialog>
  </div>-->
</template>

<script>
import axios from 'axios'
import MinusIcon from '../../icons/MinusIcon.vue';
import PlusIcon from '../../icons/PlusIcon.vue';
export default {
  components: {
    MinusIcon,
    PlusIcon
  },
  data() {
    return {
      modules: [],
      courses: [],
      selectionm: null,
      selectionc: null,
      searchm: null,
      searchc: null,
      questiontype: [],
      options: [
        {
          value: 'qcm',
          label: 'Qcm',
        },
        {
          value: 'qcs',
          label: 'Qcu',
        },
        {
          value: 'qroc',
          label: 'Qroc',
        },
        {
          value: 'clinicalcas',
          label: 'Cas clinique',
        },
      ],

      numberq: 0,
      max: 0,
      namesession: null,
      sources: [],
      source_id: [],
      interval: [],
      interval_D: null,
      interval_F: null,
      dialogWidth: this.getDialogWidth()
    }

  },

  props: {
    createSessionDialogVisible: Boolean,
    closeCreateSessionModal: Function
  },

  mounted() {
    if (this.SubscribeNow != null) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "/moduleofyear/" + this.SubscribeNow, {
          headers: {
            Authorization: "Bearer" + this.isToken,
          },
        })
        .then(({ data }) => {
          let empty = []
          data.modules.forEach(element => {
            const item = {
              label: element.title,
              value: element.id
            }

            empty.push(item);
          });
          this.modules = empty;
        })
        .catch(() => {
          this.isLoading = false;
        });

      axios
        .get(process.env.VUE_APP_API_BASE_URL + "/source", {
          headers: {
            Authorization: "Bearer" + this.isToken,
          },
        })
        .then(({ data }) => {
          this.sources = data.sources;
        })
        .catch((e) => {

          this.errormessage();
          this.isLoading = false;
        });
    }


    // pour le nom automatique de la session
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    this.namesession = "Série " + date + " | " + time;

    window.addEventListener('resize', this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    getCount() {

      if (this.interval_D === null && this.interval_F == null) {
        this.interval = []
      } else {


        if (this.interval_F !== null) {
          let tmp_date = new Date(parseInt(this.interval_F));

          this.interval_F = String(+new Date(tmp_date.getFullYear() + 1, tmp_date.getMonth(), tmp_date.getDate() - 1));
        }


        if (this.interval_D !== null && this.interval_F !== null && this.interval_D > this.interval_F) {
          window.alert("L'année de début est inférieure à l'année de fin.")
          this.interval_D = null
          this.interval_F = null
        }
        this.interval[0] = this.interval_D
        this.interval[1] = this.interval_F

        if (this.interval[0] === null && this.interval[1] !== null) {
          this.interval[0] = 0
        }
        if (this.interval[1] === null && this.interval[0] !== null) {
          this.interval[1] = String(+new Date(2040, 12, 31))
        }
        if (this.interval[0] !== null) {
          let tmp_date = new Date(parseInt(this.interval[0]));

          this.interval[0] = String(+new Date(tmp_date.getFullYear(), tmp_date.getMonth(), tmp_date.getDate() - 1));

        }

        if (this.interval[0] === null && this.interval[1] === null) {
          this.interval = []
        }
      }

      axios
        .get(process.env.VUE_APP_API_BASE_URL + "/getsessionquestionscount/" + this.selectionm + "/" + JSON.stringify(this.selectionc) + "/" + JSON.stringify(this.questiontype) + "/" + JSON.stringify(this.source_id) + "/" + JSON.stringify(this.interval), {
          headers: {
            Authorization: "Bearer" + this.isToken,
          },
        })
        .then(({ data }) => {
          this.numberq = data.result;
          this.max = data.result;
        })
        .catch(() => {
        });

    },

    closeModal() {
      this.modules = [],
        this.courses = [],
        this.courseId = null,
        this.selectionm = null,
        this.selectionc = null,
        this.searchm = null,
        this.searchc = null,
        this.questiontype = [],
        this.closeCreateSessionModal()
    },

    loadCourses(event) {
      axios.get(process.env.VUE_APP_API_BASE_URL + "/courseofmodule/" + event.value, {
        headers: {
          Authorization: "Bearer" + this.isToken,
        },
      })
        .then(({ data }) => {
          // this.isLoading = false;

          let empty = []
          data.courses.forEach(element => {
            const item = {
              label: element.title,
              value: element.id
            }

            empty.push(item);
          });
          this.courses = empty;
          this.courses = [...this.courses, { label: 'Diverses', value: 'diverses' }]
        })
        .catch(() => {
          // this.isLoading = false;
        });
    },

    getIdCourse(event) {
      this.courseId = event.target.value;
    },


    createSession() {

      axios.post(process.env.VUE_APP_API_BASE_URL + "/session",
        {
          name: this.namesession,
          user_ID: this.User.id,
          coursesID: this.selectionc,
          types: this.questiontype,
          nombre: this.numberq,
          sources: this.source_id,
          dates: this.interval
        },
        {
          headers: {
            Authorization: "Bearer" + this.isToken,
          },
        })
        .then(({ data }) => {
          // this.isLoading = false;
          this.$router.push("/dashboard/currentSession/?id=" + data.result);
          this.closeCreateSessionModal();
        })
        .catch(() => {
          // this.isLoading = false;
        });
    },

    increment() {
      if (this.numberq < this.max) {
        this.numberq++;
      }
    },
    decrement() {
      if (this.numberq > 0) {
        this.numberq--;
      }
    },

    getDialogWidth() {
      return window.innerWidth <= 768 && window.innerWidth > 576 ? '80%' : window.innerWidth <= 576 ? '90%' : '60%';
    },
    handleResize() {
      this.dialogWidth = this.getDialogWidth();
    },
  },


  computed: {
    isToken() {
      return this.$store.getters.get_token;
    },
    User() {
      return this.$store.getters.get_user;
    },
    SubscribeNow() {
      return this.$store.getters.get_subscribeNow
    },
    filterModules() {
      if (this.searchm) {
        return this.modules.filter((item) => {
          return item.label.toLowerCase().includes(this.searchm.toLowerCase())
        })
      } else {
        return this.modules
      }
    },

    filterCourses() {
      if (this.searchc) {
        return this.courses.filter((item) => {
          return item.label.toLowerCase().includes(this.searchc.toLowerCase())
        })
      } else {
        return this.courses
      }
    }
  },

}
</script>

<style></style>